import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { z } from 'zod'

import { TimeGranularity } from '@common/util/enums'

export enum LocalStorageKey {
  ADMIN_DATA = 'adminData',
  ADMIN_PRICE_RECOMMENDATIONS_TAB = 'adminPriceRecommendationsTab',
  ADMIN_WRITE_MODE = 'adminWriteMode',
  CONVERSION_WINDOW_DAYS = 'conversionWindowDays',
  LEASES_PAGE_TAB = 'leasesPageTab',
  OVERVIEW_VIEW = 'overviewView',
  PORTFOLIO_SID = 'portfolioSid',
  PRICING_VIEW_GROUPING_LEVEL = 'pricingViewGroupingLevel',
  PROPERTY_AMENITIES_TAB = 'propertyAmenitiesTab',
  PROPERTY_SID = 'propertySid',
  PROPERTY_VIEW = 'propertyView',
  RENEWALS_DATE_RANGE = 'renewalsDateRange', // YYYY-MM-DD,YYYY-MM-DD
  RENEWAL_REQUESTS_ASSIGNEE_TAB = 'renewalRequestsAssigneeTab',
  RENEWAL_REQUESTS_STATE_TAB = 'renewalRequestsStateTab',
  RENEWAL_SUMMARY_DATE_GROUPING = 'renewalSummaryDateGrouping',
  RENEWALS_PROPERTY_SID = 'renewalsPropertySid',
  RENEWALS_VIEW = 'renewalsView',
  REPORTS_VIEW = 'reportsView',
  SIDEBAR_NAV_COLLAPSED = 'sidebarNavCollapsed',
  PRICE_HISTORY_CHART_METRICS = 'priceHistoryChartMetrics',
  UNIT_AVAILABILITY_CHART_GROUP_BY = 'unitAvailabilityChartGroupBy',
}

const localStorageKeyToSchema = {
  [LocalStorageKey.ADMIN_DATA]: z.string(),
  [LocalStorageKey.ADMIN_PRICE_RECOMMENDATIONS_TAB]: z.string(),
  [LocalStorageKey.ADMIN_WRITE_MODE]: z.string(),
  [LocalStorageKey.CONVERSION_WINDOW_DAYS]: z.number(),
  [LocalStorageKey.LEASES_PAGE_TAB]: z.string(),
  [LocalStorageKey.OVERVIEW_VIEW]: z.string(),
  [LocalStorageKey.PORTFOLIO_SID]: z.string(),
  [LocalStorageKey.PRICING_VIEW_GROUPING_LEVEL]: z.string(),
  [LocalStorageKey.PROPERTY_AMENITIES_TAB]: z.string(),
  [LocalStorageKey.PROPERTY_SID]: z.string(),
  [LocalStorageKey.PROPERTY_VIEW]: z.string(),
  [LocalStorageKey.RENEWALS_DATE_RANGE]: z.string(),
  [LocalStorageKey.RENEWAL_REQUESTS_ASSIGNEE_TAB]: z.string(),
  [LocalStorageKey.RENEWAL_REQUESTS_STATE_TAB]: z.string(),
  [LocalStorageKey.RENEWAL_SUMMARY_DATE_GROUPING]: z.string(),
  [LocalStorageKey.RENEWALS_VIEW]: z.string(),
  [LocalStorageKey.RENEWALS_PROPERTY_SID]: z.string(),
  [LocalStorageKey.REPORTS_VIEW]: z.string(),
  [LocalStorageKey.SIDEBAR_NAV_COLLAPSED]: z.boolean(),
  [LocalStorageKey.PRICE_HISTORY_CHART_METRICS]: z.array(z.string()),
  [LocalStorageKey.UNIT_AVAILABILITY_CHART_GROUP_BY]: z.nativeEnum(TimeGranularity),
} as const

export function useLocalStorage<K extends LocalStorageKey>(
  key: K,
  defaultValue: z.infer<(typeof localStorageKeyToSchema)[K]>
): [
  z.infer<(typeof localStorageKeyToSchema)[K]>,
  (value: z.infer<(typeof localStorageKeyToSchema)[K]>) => void,
]
export function useLocalStorage<K extends LocalStorageKey>(
  key: K
): [
  z.infer<(typeof localStorageKeyToSchema)[K]> | null,
  (value: z.infer<(typeof localStorageKeyToSchema)[K]>) => void,
]
export function useLocalStorage<K extends LocalStorageKey>(
  key: K,
  defaultValue?: z.infer<(typeof localStorageKeyToSchema)[K]>
): [
  z.infer<(typeof localStorageKeyToSchema)[K]> | null,
  (value: z.infer<(typeof localStorageKeyToSchema)[K]>) => void,
] {
  const [value, setValue] = useState<z.infer<(typeof localStorageKeyToSchema)[K]> | null>(() => {
    const item = localStorage.getItem(key)
    if (item === null) return defaultValue ?? null

    try {
      const schema = localStorageKeyToSchema[key]
      let parsedValue: any

      if (schema._def.typeName === 'ZodNumber') {
        parsedValue = parseFloat(item)
      } else if (schema._def.typeName === 'ZodBoolean') {
        parsedValue = item === 'true'
      } else if (schema._def.typeName === 'ZodArray') {
        parsedValue = JSON.parse(item)
      } else {
        parsedValue = item
      }

      return schema.parse(parsedValue)
    } catch (error) {
      console.error(`[useLocalStorage] validation failed for ${key}`, error)
      // Remove invalid value from localStorage
      localStorage.removeItem(key)
      return defaultValue ?? null
    }
  })

  const setLocalStorageValue = (value: z.infer<(typeof localStorageKeyToSchema)[K]>) => {
    let stringifiedValue: string
    try {
      if (['string', 'boolean', 'number'].includes(typeof value)) {
        stringifiedValue = String(value)
      } else if (value === null || value === undefined) {
        throw new Error('Cannot store null or undefined values')
      } else {
        stringifiedValue = JSON.stringify(value)
      }
      localStorage.setItem(key, stringifiedValue)
      setValue(value)
    } catch (error) {
      Sentry.captureMessage(`[useLocalStorage] Failed to stringify value for ${key}`, {
        level: 'error',
        extra: { error },
      })
    }
  }

  return [value, setLocalStorageValue]
}
